import clsx from 'clsx';
import Icon from 'components/ui/Icon';
import Image from 'components/ui/Image';
import Stars from 'components/ui/Stars';
import config from 'config';
import tailwind from 'utils/breakpoints';
import { useTranslation } from 'hooks/useTranslation';

export type HappyCustomersSliderItemProps = {
  image: string;
  icon: string;
  rating: number;
  treatment: string;
  name: string;
  country: string;
  text: string;
  show?: string[];
};

type Props = {
  item: HappyCustomersSliderItemProps;
  className?: string;
};

const HappyCustomersSliderItem = ({ item, className }: Props): JSX.Element => {
  const { locale, t } = useTranslation();

  return (
    <div
      className={clsx(
        'mx-1 md:mx-3 rounded border border-silver-light bg-white overflow-hidden',
        className,
      )}
    >
      <div className="mb-8 sm:my-0 rounded">
        <div className="w-full h-48 full-lazyload overflow-hidden relative">
          <Image
            src={item.image}
            alt={item.name}
            fill
            className="object-cover"
          />
        </div>
        <div className="p-4 h-full sm:h-80 lg:h-full">
          <div className="flex items-center mb-6">
            <Icon
              className="inline-flex mr-2 rounded-full bg-secondary p-3 w-12 h-12"
              type={item.icon}
              color={tailwind.theme.backgroundColor.navy}
            />

            <p className="font-netto text-navy">
              <span>{t(`treatment.${item.treatment}`)}</span>
              <Stars
                rating={item.rating}
                color={tailwind.theme.backgroundColor.secondary}
              />
            </p>
          </div>
          <p>
            <span className="font-bold">{item.name}</span>
            {config.homepage.popularTreatmentPrice.includes(locale) && (
              <>, {item.country}</>
            )}
            :
          </p>
          <p>{item.text}</p>
        </div>
      </div>
    </div>
  );
};

export default HappyCustomersSliderItem;
