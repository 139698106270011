/**
 * Get text for Qunoscore
 */
export const getQunoScoreStatus = (score: number): string => {
  if (score >= 9) {
    return 'doctorProfile.qunoscoreSection.excellent';
  }

  if (score >= 7) {
    return 'doctorProfile.qunoscoreSection.veryGood';
  }

  if (score >= 5) {
    return 'doctorProfile.qunoscoreSection.good';
  }

  return 'doctorProfile.qunoscoreSection.satisfactory';
};
