export const formatCurrency = (
  value: number,
  currency: string,
  locale: string,
): string => {
  let currencyLocale = locale;
  if (
    currencyLocale === 'es' ||
    currencyLocale === 'it' ||
    currencyLocale === 'nl' ||
    currencyLocale === 'fr'
  ) {
    currencyLocale = 'de';
  }

  return new Intl.NumberFormat(currencyLocale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};
