import { IMGS_PATH } from 'constants/image';
import clsx from 'clsx';
import Image from 'components/ui/Image';
import { useTranslation } from 'hooks/useTranslation';

type Props = {
  className?: string;
};

const PreviouslyFeaturedOn = ({ className }: Props): JSX.Element => {
  const { locale, t } = useTranslation();

  return (
    <div
      className={clsx('max-w-screen-xl mx-auto px-5 md:px-4 py-12', className)}
    >
      <div className="lg:hidden uppercase font-bold text-navy">
        {t('home.previouslyFeaturedOn')}
      </div>
      <div className="grid grid-cols-2 md:flex items-center justify-between">
        <div className="hidden lg:block uppercase font-bold text-navy text-center">
          {t('home.previouslyFeaturedOn')}
        </div>
        <div className="mx-auto my-8 lg:my-0">
          <Image
            src={`${IMGS_PATH.PUBLIC}/faz.svg`}
            alt="FAZ"
            width={202}
            height={28}
          />
        </div>
        <div className="mx-auto my-8 lg:my-0">
          <Image
            src={`${IMGS_PATH.PUBLIC}/forbes.svg`}
            alt="Forbes"
            width={117}
            height={38}
          />
        </div>
        {'de' === locale ? (
          <>
            <div className="mx-auto my-8 lg:my-0">
              <Image
                src={`${IMGS_PATH.PUBLIC}/der-spiegel.svg`}
                alt="Der Spiegel"
                width={175}
                height={24}
              />
            </div>
            <div className="mx-auto my-8 lg:my-0">
              <Image
                src={`${IMGS_PATH.PUBLIC}/handelsblatt.svg`}
                alt="Handelsblatt"
                width={175}
                height={26}
              />
            </div>
          </>
        ) : (
          <>
            <div className="mx-auto my-8 lg:my-0">
              <Image
                src={`${IMGS_PATH.PUBLIC}/bbc.svg`}
                alt="BBC"
                width={146}
                height={43}
              />
            </div>
            <div className="mx-auto my-8 lg:my-0">
              <Image
                src={`${IMGS_PATH.PUBLIC}/the-economist.svg`}
                alt="The Economist"
                width={146}
                height={45}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PreviouslyFeaturedOn;
