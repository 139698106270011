import clsx from 'clsx';
import Image from 'components/ui/Image';
import Link from 'components/ui/Link';
import Stars from 'components/ui/Stars';
import config from 'config';
import { formatCurrency } from 'utils/currency';
import { formatNumber } from 'utils/number';
import { useTranslation } from 'hooks/useTranslation';
import styles from './PopularTreatments.module.scss';

export type PopularTreatmentsSliderItemProps = {
  title: string;
  price: number;
  rating: number;
  reviews: number | string;
  image: string;
  speciality: Record<string, string | undefined>;
  treatment: Record<string, string | undefined>;
  show?: string[];
};

type Props = {
  item: PopularTreatmentsSliderItemProps;
  className?: string;
};

const PopularTreatmentsSliderItem = ({
  item,
  className,
}: Props): JSX.Element => {
  const { locale, t } = useTranslation();

  return (
    <div
      className={clsx('flex flex-row lg:w-1/4 px-1 md:px-2 pb-2', className)}
    >
      <div className="relative flex flex-col w-full border rounded">
        <div className={clsx('relative overflow-hidden', styles.imageHeight)}>
          <Image
            src={item.image}
            alt={item.title}
            fill
            className="object-cover"
          />
        </div>
        <div className="grow pt-4">
          <p className="font-netto text-xl popular-slider-title px-4">
            <Link
              href={`/${locale}/${item.speciality[locale]}/${item.treatment[locale]}`}
            >
              <a
                className="hover:underline stretched-link text-primary no-underline"
                title={t(`treatment.${item.title}`)}
              >
                {t(`treatment.${item.title}`)}
              </a>
            </Link>
          </p>
          {config.homepage.popularTreatmentPrice.includes(locale) && (
            <p className="px-4 text-sm">
              {t('startingFrom')}{' '}
              <span className="font-bold">
                {formatCurrency(item.price, 'EUR', locale)}
              </span>
            </p>
          )}
        </div>
        <div className="flex shrink justify-between mt-4 h-5 px-4 mb-4">
          <div className="flex items-center text-sm lg:w-2/5 xl:w-auto">
            <Stars rating={item.rating} />
            <span className="ml-2 font-bold">
              {formatNumber(item.rating, locale)}
            </span>
          </div>
          <span className="text-sm">
            ({formatNumber(item.reviews, locale)} {t('reviews')})
          </span>
        </div>
      </div>
    </div>
  );
};

export default PopularTreatmentsSliderItem;
