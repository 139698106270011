import { LINK_SHARE_IMAGE } from 'constants/image';
import Head from 'next/head';
import config from 'config';
import { useCountry } from 'hooks/useCountry';
import { useTranslation } from 'hooks/useTranslation';

type Props = {
  reviewStats: { avg_rating: number; total: number | undefined } | null;
  localizedUrl?: boolean;
};

const StructuredData = ({ reviewStats, localizedUrl }: Props): JSX.Element => {
  const { t, locale } = useTranslation();
  const { country } = useCountry();

  const baseSchema = {
    '@context': 'http://schema.org/',
    '@type': 'MedicalOrganization',
    url: `https://${config.app.domains.proxy.production}/${
      localizedUrl ? locale : ''
    }`,
    isAcceptingNewPatients: true,
    knowsLanguage: locale,
    sameAs: [
      'https://www.instagram.com/qunomedical/',
      'https://twitter.com/qunomedical',
      'https://www.linkedin.com/company/qunomedical',
    ],
    contactPoint: {
      '@type': 'ContactPoint',
      telephone:
        config.countries[country as keyof typeof config.countries].phone,
      email: 'contact@qunomedical.com',
      availableLanguage: locale,
      contactType: t('home.customerCare'),
      areaServed: locale,
    },
    legalName: 'Qunomedical GmbH',
    name: 'Qunomedical',
    slogan: t('home.hero.headline'),
    identifier: 'Qunomedical',
    logo: {
      '@type': 'ImageObject',
      url: [
        `${LINK_SHARE_IMAGE}?fit=fill&w=170&h=122`,
        `${LINK_SHARE_IMAGE}?fit=fill&w=470&h=338`,
        `${LINK_SHARE_IMAGE}?fit=fill&w=770&h=555`,
      ],
    },
    areaServed: locale,
    aggregateRating: [
      {
        '@type': 'AggregateRating',
        ratingValue: reviewStats?.avg_rating.toFixed(1) || 0,
        reviewCount: reviewStats?.total || 0,
      },
    ],
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(baseSchema) }}
      />
    </Head>
  );
};

export default StructuredData;
