import { FunnelId } from 'constants/funnel';
import ExternalFunnelButton from 'components/Shared/ExternalFunnel/ExternalFunnelButton';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import { useTranslation } from 'hooks/useTranslation';

const specialities = [
  {
    title: 'cardiovascularProcedures',
    icon: 'Cardiology',
    link: { en: 'cardiovascular-procedures', de: 'kardiovaskulaere-eingriffe' },
  },
  {
    title: 'dentistry',
    icon: 'Tooth',
    link: { en: 'dentistry', de: 'zahnmedizin' },
    treatments: [
      { title: 'veneers', link: { en: 'veneers', de: 'veneers' } },
      {
        title: 'dentalImplant',
        link: { en: 'dental-implant', de: 'zahnimplantat' },
      },
    ],
  },
  {
    title: 'earNoseAndThroatProcedures',
    icon: 'FaceTreatments',
    link: {
      en: 'ear-nose-and-throat-procedures',
      de: 'hals-nasen-ohrenheilkunde',
    },
  },
  {
    title: 'oncology',
    icon: 'Oncology',
    link: { en: 'oncology', de: 'onkologie' },
  },
  {
    title: 'opthalmology',
    icon: 'Eye',
    link: { en: 'ophthalmology', de: 'augenheilkunde' },
    treatments: [
      {
        title: 'lasik',
        link: { en: 'lasik', de: 'lasik' },
      },
    ],
  },
  {
    title: 'orthopedics',
    icon: 'Bone',
    link: { en: 'orthopedics', de: 'orthopaedie' },
  },
  {
    title: 'plasticSurgery',
    icon: 'PlasticSurgery',
    link: { en: 'plastic-surgery', de: 'plastische-chirurgie' },
    treatments: [
      {
        title: 'hairTransplant',
        link: { en: 'hair-transplant', de: 'haartransplantation' },
      },
    ],
  },
  {
    title: 'reproductiveMedicine',
    icon: 'Gender',
    link: { en: 'reproductive-medicine', de: 'reproduktionsmedizin' },
    treatments: [
      {
        title: 'inVitoFertilization',
        link: { en: 'invitro-fertilization', de: 'in-vitro-fertilisation' },
      },
    ],
  },
  {
    title: 'urology',
    icon: 'Urology',
    link: { en: 'urology', de: 'urologie' },
  },
  {
    title: 'weightLossSurgery',
    icon: 'Balance',
    link: { en: 'weight-loss-surgery', de: 'operative-gewichtsreduktion' },
  },
];

const SpecialitiesOverview = (): JSX.Element => {
  const { locale, t } = useTranslation();

  return (
    <div className="grid grid-cols-2 lg:grid-cols-3">
      {specialities.map((speciality, i) => {
        const specialityLink =
          speciality.link[locale as keyof typeof speciality.link];

        return (
          <div key={i} className="mb-4 md:flex">
            <div className="p-4 md:p-0 mx-auto md:mx-0 rounded-full bg-secondary w-16 h-16 md:w-24 md:h-24 mb-2 md:mb-12 md:mr-6 flex items-center justify-center">
              <Icon type={speciality.icon} color="#155263" />
            </div>
            <div className="md:w-48 text-center md:text-left">
              <Link href={`/${locale}/${specialityLink}`}>
                <a className="text-navy text-xl md:text-2xl text-center md:text-left font-netto hover:underline hyphenate">
                  {t(`speciality.${speciality.title}`)}
                </a>
              </Link>
              {speciality.treatments?.map((treatment, index) => (
                <Link
                  key={index}
                  href={`/${locale}/${specialityLink}/${
                    treatment.link[locale as keyof typeof treatment.link]
                  }`}
                >
                  <a
                    title={t(`treatment.${treatment.title}`)}
                    className="underline hidden md:block hover:text-siam"
                  >
                    {t(`treatment.${treatment.title}`)}
                  </a>
                </Link>
              ))}
              <Link href={`/${locale}/${specialityLink}`}>
                <a
                  title={t('more')}
                  className="underline hidden md:block hover:text-siam"
                >
                  {t('more')}...
                </a>
              </Link>
            </div>
          </div>
        );
      })}
      <div className="md:flex">
        <div className="p-4 md:p-0 mx-auto md:mx-0 rounded-full bg-secondary w-16 h-16 md:w-24 md:h-24 mb-4 md:mb-12 md:mr-6 flex items-center justify-center">
          <Icon type="QuestionMark" color="#155263" />
        </div>
        <div className="md:w-48">
          <p className="text-navy text-xl md:text-2xl text-center md:text-left font-netto">
            {t('dontSeeWhatYoureLookingFor')}
          </p>
          <ExternalFunnelButton
            funnelId={FunnelId.CONTACT_FORM}
            renderButton={(openFunnel) => {
              return (
                <span
                  className="!hidden md:!inline cursor-pointer underline"
                  title="more"
                  onClick={openFunnel}
                >
                  {t('footer.contactUs')}
                </span>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SpecialitiesOverview;
