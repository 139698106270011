import clsx from 'clsx';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import CustomSliderArrow from 'components/ui/CustomSliderArrow';
import config from 'config';
import tailwind from 'utils/breakpoints';
import { useTranslation } from 'hooks/useTranslation';
import { useVerticalSwipePreventer } from 'hooks/useVerticalSwipePreventer';
import HappyCustomersSliderItem from './HappyCustomersSliderItem';
import PopularDoctorsSliderItem from './PopularDoctorsSliderItem';
import PopularTreatmentsSliderItem from './PopularTreatmentsSliderItem';
import styles from './Slider.module.scss';
import type { ResponsiveType } from 'react-multi-carousel';
import type { PopularTreatmentsSliderItemProps } from './PopularTreatmentsSliderItem';
import type { HappyCustomersSliderItemProps } from './HappyCustomersSliderItem';
import type { PopularDoctorsSliderItemProps } from './PopularDoctorsSliderItem';

const sliderContentMapping = {
  popularDoctors: {
    classes: {
      showAfter: styles.showAfter,
      showBefore: styles.showBefore,
      arrows: '',
    },
    config: {
      threshold: 2,
      responsive: {
        desktop: {
          breakpoint: { max: 4000, min: 1024 },
          items: 2,
          partialVisibilityGutter: 60,
        },
        tablet: {
          breakpoint: { max: 1023, min: 640 },
          items: 2,
          partialVisibilityGutter: 60,
        },
        mobile: {
          breakpoint: { max: 639, min: 0 },
          items: 1,
          partialVisibilityGutter: 60,
        },
      },
    },
  },
  happyCustomers: {
    classes: {
      showAfter: styles.showAfterSecondaryBright,
      showBefore: styles.showBeforeSecondaryBright,
      arrows: '',
    },
    config: null,
  },
  popularTreatments: {
    classes: {
      showAfter: styles.showAfter,
      showBefore: styles.showBefore,
      arrows: '',
    },
    config: {
      threshold: 4,
      responsive: {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 3,
          partialVisibilityGutter: 40,
        },
        desktop: {
          breakpoint: { max: 2999, min: 1024 },
          items: 3,
          partialVisibilityGutter: 40,
        },
        tablet: {
          breakpoint: { max: 1023, min: 768 },
          items: 2,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: { max: 767, min: 0 },
          items: 1,
          partialVisibilityGutter: 60,
        },
      },
    },
  },
};

type SliderItem =
  | PopularDoctorsSliderItemProps
  | HappyCustomersSliderItemProps
  | PopularTreatmentsSliderItemProps;

type SliderConfig = {
  itemWidth?: string;
  noFullWidth?: boolean;
  threshold?: number;
  responsive?: ResponsiveType;
};

type Props = {
  blockId?: string;
  type: 'popularDoctors' | 'happyCustomers' | 'popularTreatments';
  items: SliderItem[];
  title?: string;
  sliderItemClasses?: string;
  nonSliderItemClasses?: string;
  smallScreenViewPorts?: string;
  sliderConfig?: SliderConfig;
  allowCenterMode?: boolean;
  showFade?: boolean;
};

const Slider = ({
  blockId,
  type,
  items,
  title,
  sliderItemClasses,
  nonSliderItemClasses,
  sliderConfig,
  smallScreenViewPorts = '1023px',
  allowCenterMode = true,
  showFade = true,
}: Props): JSX.Element => {
  const { locale } = useTranslation();

  const containerRef = useRef(null);
  const [curSlide, setCurSlide] = useState(0);
  const [centerMode, setCenterMode] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useVerticalSwipePreventer(containerRef);

  const sliderContent = sliderContentMapping[type];

  const configOrDefault: SliderConfig =
    sliderConfig ||
    sliderContent.config ||
    config.defaults.treatmentsCardSlider;

  const isMobileScreen = useCallback(() => {
    const isMobile = window.matchMedia(
      `(max-width: ${smallScreenViewPorts})`,
    ).matches;
    setIsSmallScreen(isMobile);
    setCenterMode(!isMobile);
  }, []);

  useEffect(() => {
    isMobileScreen();
    window.addEventListener('resize', isMobileScreen);

    return () => {
      window.removeEventListener('resize', isMobileScreen);
    };
  }, []);

  const filteredItems = items.filter(
    (item) => !('show' in item) || item.show?.includes(locale),
  );

  const renderSliderItem = (
    item: SliderItem,
    className: string,
  ): JSX.Element => {
    if (type === 'popularDoctors') {
      return (
        <PopularDoctorsSliderItem
          className={className}
          item={item as PopularDoctorsSliderItemProps}
        />
      );
    }

    if (type === 'happyCustomers') {
      return (
        <HappyCustomersSliderItem
          className={className}
          item={item as HappyCustomersSliderItemProps}
        />
      );
    }

    return (
      <PopularTreatmentsSliderItem
        className={className}
        item={item as PopularTreatmentsSliderItemProps}
      />
    );
  };

  return (
    <div
      ref={containerRef}
      id={blockId}
      className={clsx(
        configOrDefault.threshold &&
          configOrDefault.threshold <= filteredItems.length &&
          '-mx-6',
        'lg:mx-0 mb-8 relative w-full',
      )}
    >
      {title && <h2 className="text-primary text-3xl mb-10 mx-3">{title}</h2>}
      {(configOrDefault.threshold &&
        configOrDefault.threshold < filteredItems.length) ||
      (configOrDefault.threshold &&
        configOrDefault.threshold <= filteredItems.length &&
        isSmallScreen) ? (
        <Carousel
          responsive={configOrDefault.responsive || {}}
          arrows
          centerMode={centerMode && allowCenterMode}
          partialVisible={!centerMode}
          customLeftArrow={
            <CustomSliderArrow
              direction="left"
              className={clsx(
                'bg-white rounded-full h-8 w-8 p-2 left-0 -ml-0',
                sliderContent.classes.arrows,
              )}
              color={tailwind.theme.backgroundColor.navy}
              style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)' }}
            />
          }
          customRightArrow={
            <CustomSliderArrow
              direction="right"
              className={clsx(
                'bg-white rounded-full h-8 w-8 p-2 right-0 -mr-0',
                sliderContent.classes.arrows,
              )}
              color={tailwind.theme.backgroundColor.navy}
              style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)' }}
            />
          }
          removeArrowOnDeviceType={['mobile']}
          containerClass={clsx(
            'flex relative items-center lg:overflow-hidden px-6 lg:px-0',
            styles.treatmentsCarousel,
            showFade && curSlide > 0
              ? [
                  sliderContent.classes.showBefore,
                  sliderContent.classes.showAfter,
                ]
              : showFade &&
                  curSlide < filteredItems.length &&
                  sliderContent.classes.showAfter,
          )}
          sliderClass="flex relative flex-row list-none p-0 m-0"
          itemClass={styles.carouselItem}
          beforeChange={(nextSlide) => setCurSlide(nextSlide)}
        >
          {filteredItems.map((item, i) => {
            return (
              <Fragment key={i}>
                {renderSliderItem(item, clsx('h-full', sliderItemClasses))}
              </Fragment>
            );
          })}
        </Carousel>
      ) : (
        <div className="sm:flex lg:flex lg:flex-row lg:flex-wrap">
          <>
            {filteredItems.map((item, i) => {
              return (
                <Fragment key={i}>
                  {renderSliderItem(
                    item,
                    clsx(
                      filteredItems.length === 1 && isSmallScreen
                        ? [
                            configOrDefault.noFullWidth
                              ? configOrDefault.itemWidth
                              : 'w-full',
                          ]
                        : configOrDefault.itemWidth || 'w-1/2',
                      nonSliderItemClasses,
                    ),
                  )}
                </Fragment>
              );
            })}
          </>
        </div>
      )}
    </div>
  );
};

export default Slider;
