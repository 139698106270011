import Stars from 'components/ui/Stars';
import TrustBox from 'components/ui/TrustBox';
import { formatNumber } from 'utils/number';
import { useTranslation } from 'hooks/useTranslation';

const TrustBanner = (): JSX.Element => {
  const { locale, t } = useTranslation();

  return (
    <div className="bg-sandBlueLighter">
      <div className="max-w-screen-xl mx-auto px-5 md:px-4 py-12 md:py-10 text-siam text-center">
        <div className="grid grid-cols-2 md:grid-cols-4">
          <div className="h-24 overflow-hidden">
            <TrustBox
              className="mb-0 w-40 mx-auto"
              height={100}
              theme="light"
            />
          </div>
          <div className="uppercase flex justify-center items-center">
            <div>
              <p className="font-netto text-4xl">
                {formatNumber(6500, locale)}+
              </p>
              <p>{t('satisfiedPatients')}</p>
            </div>
          </div>
          <div className="uppercase my-6 md:my-0 flex justify-center items-center">
            <div>
              <p className="font-netto text-4xl">
                24/{locale === 'it' ? 24 : 7}
              </p>
              <p>{t('patientServiceAccess')}</p>
            </div>
          </div>
          <div className="uppercase my-6 md:my-0 flex justify-center items-center">
            <div>
              <div className="flex justify-center items-center">
                <div>
                  <Stars rating={4.6} className="mx-auto" />
                </div>
                <p className="font-netto text-4xl ml-4">
                  {formatNumber(parseFloat('4.6'), locale)}
                </p>
              </div>
              Google Rating
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustBanner;
