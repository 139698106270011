import { useState } from 'react';
import Search from 'components/Pages/Search/Search';
import Link from 'components/ui/Link';
import config from 'config';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'hooks/useTranslation';
import styles from './SearchSection.module.scss';

const SearchSection = (): JSX.Element => {
  const { locale, t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const openSearch = (): void => {
    setIsSearchOpen(true);
    trackEvent({
      category: 'SearchBar',
      action: 'Form|Click',
      label: 'Form|SearchBar|HomePage|Click',
    });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <p className={styles.headline}>{t('search')}</p>
          <div className={styles.inputWrapper}>
            <label className={styles.label}>{t('search.imLoogingFor')}</label>
            <input
              type="text"
              placeholder={t('search.placeholder')}
              className={styles.input}
              onClick={openSearch}
            />
          </div>
          <p className={styles.popularHeadline}>{t('search.popularSearch')}</p>
          <ul className={styles.popularList}>
            {config.search.recent[
              locale as keyof typeof config.search.recent
            ].map((suggestion, i) => (
              <li key={i} className={styles.popularItem}>
                <Link
                  href={suggestion.url}
                  title={suggestion.title}
                  className={styles.popularLink}
                  legacyBehavior={false}
                >
                  {suggestion.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Search
        open={isSearchOpen}
        onClose={() => {
          setIsSearchOpen(false);
        }}
        openIdentifier="HomePage"
      />
    </>
  );
};

export default SearchSection;
